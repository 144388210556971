import { useEffect, useState, useCallback, useReducer } from "react";

import {
  handleRemoveItemFromLayout
} from "./helpers";


import { v4 as uuidv4 } from 'uuid';

import "./styles.css";

import Full3Columns from "../../layout/Page/Full page/3columns";
import { withTranslation } from "react-i18next";
import DataList from "../../pages/Form/Tabs/Designer/DataList";
import DesignerStepper from "../../pages/Form/Tabs/Designer/DesignerStepper";
import Details from "../../pages/Form/Tabs/Designer/Details";
import Status from "../Buttons/Status";
import Switch from "../Inputs/Switch";
import Visualizer from "../../pages/Form/Tabs/Designer/Visualizer";
import Setup from "../../pages/Form/Tabs/Designer/Setup";
import Logic from "../../pages/Form/Tabs/Designer/Logic";
import ChoiceModal from "../Popup/choiceModal";

function Container({ t, data, scroll, changeStep, save, candidature, loading, upToDate, theme, curentIndex, payment, moveStep }) {
  const [layout, setLayout] = useState([]);
  const [components, setComponents] = useState({});
  const [optionM, setOptionM] = useState(null);
  const [steps, setSteps] = useState([]);
  const [visualizer, setVisualizer] = useState(false);
  const [logic, setLogic] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [lineList, setLineList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [logicError, setLogicError] = useState(false);
  const [modalError, setModalError] = useState(false);

  useEffect(() => {
    // setLogicError(false)
    // setModalError(false)
    if (data && candidature) {
      layout.length = 0;
      let localId;
      if (data.questions && typeof data.questions !== "string" && data.questions.length > 0) {
        data.questions.forEach(q => {
          if (q.error) {
            let errorList = []
            candidature.ErrorsId.forEach(err => {
              if (err.includes(q.name)) {
                errorList.push(err.split(':').pop())
              }
            })
            q.errorList = errorList
          }
          // localId = uuidv4();
          localId = q.id;
          q.selected = false;
          components[localId] = { content: q.name, data: q, id: localId, type: q.name };
          layout.push({ id: uuidv4(), type: "row", children: [{ id: uuidv4(), type: "column", children: [{ type: "component", id: localId }] }] });
        })
        var error = false
        var cand = candidature.Step[curentIndex]
        cand.questions.forEach(question => {
          if (errorCheck(question, cand.questions)) {
            error = true
          }
        })
        setLogicError(error)
      }
      if (data.name === "") {
        candidatureSetup()
      }
    }
  }, [data, candidature])

  function candidatureSetup() {
    let localSteps = []
    candidature.Step.forEach(step => {
      localSteps.push({ name: step.name })
    })
    setSteps(localSteps);
  }

  useEffect(() => {
    let localSteps = []
    if (candidature.Step && candidature.Step.length) {
      candidature.Step.forEach(step => {
        localSteps.push({ name: step.name })
      })
    }
    setSteps(localSteps);

  }, [candidature]);


  function handleDetails(option, value) {
    if (option && value) {
      const componentLocal = components[option];
      setSelectedItem(componentLocal);
      return true
    } else {
      setSelectedItem(null);
      return false
    }
  }

  function handleOnChangeDetails(newOption) {
    var componentLocal = components[newOption.id];
    componentLocal.data.label = newOption.label;
    componentLocal.data.description = newOption.description;
    componentLocal.data.tooltip = newOption.tooltip;
    componentLocal.data.conditional = newOption.conditional;
    componentLocal.data.conditionalList = newOption.conditionalList;
    componentLocal.data.readOnly = newOption.readOnly;
    componentLocal.data.hidden = newOption.hidden;
    componentLocal.data.required = newOption.required;
    componentLocal.data.optionsCustom = newOption.optionsCustom;
    componentLocal.data.optionsCustomList = newOption.optionsCustomList;
    setComponents(componentsLocal => {
      componentsLocal[newOption.id] = componentLocal;
      return componentsLocal;
    });
    handleDetails(null, false);
    presave()
    save();
  }

  function presave() {
    var error = false
    for (const [key, value] of Object.entries(components)) {
      for (let i = 0; i < candidature.Step.length; i++) {
        const step = candidature.Step[i];
        for (let j = 0; j < step.questions.length; j++) {
          const question = step.questions[j];
          if (question.id === key) {
            question.label = value.data.label;
            question.description = value.data.description;
            question.tooltip = value.data.tooltip;
            question.conditional = value.data.conditional;
            question.conditionalList = value.data.conditionalList;
            question.sub = value.data.sub;
            question.readOnly = value.data.readOnly;
            question.required = value.data.required;
            if (i === curentIndex && errorCheck(question, step.questions)) {
              error = true;
            }
          }
          // if (question.conditionalList && typeof question.conditionalList === "object" && question.conditionalList.length > 0) {
          //   for (let i = 0; i < question.conditionalList.length; i++) {
          //     const comp = question.conditionalList[i];
          //     if (comp.cond && typeof comp.cond !== "object" && comp.cond !== null) {
          //       comp.cond = [comp.cond];
          //     }
          //   }
          // }
        }
      }
    }
    for (const [key, value] of Object.entries(components)) {
      if (value.content === "aa_nom_entreprise") {
        let tmp = Object.assign({},value)
        delete tmp.ref
      }
    }
    conditionalListCleanup()
    setLogicError(error)
  }

  function conditionalListCleanup() {
    console.log("conditionalListCleanup")
    for (let i = 0; i < candidature.Step.length; i++) {
      const step = candidature.Step[i];
      for (let j = 0; j < step.questions.length; j++) {
        const question = step.questions[j];
        if (question.conditionalList && typeof question.conditionalList === "object" && question.conditionalList.length > 0) {
          for (let i = 0; i < question.conditionalList.length; i++) {
            const comp = question.conditionalList[i];
            // console.log(comp)
            if (comp.cond && typeof comp.cond !== "object" && comp.cond !== null) {
              comp.cond = [comp.cond];
            } else if (comp.cond && typeof comp.cond === "object" && comp.cond !== null && comp.cond.length > 0 && typeof comp.cond[0] === "object") {
              comp.cond = comp.cond[0];
            }
            if (comp?.And !== null && comp?.And?.cond != null) {
              conditionalListCleanupRec(comp.And)
            }
          }
        }
      }
    }
  }

  function conditionalListCleanupRec(comp) {
    // console.log(comp)
    if (comp.cond && typeof comp.cond !== "object" && comp.cond !== null) {
      comp.cond = [comp.cond];
    }
    if (comp?.And !== null && comp?.And?.cond != null) {
      conditionalListCleanupRec(comp.And)
    }
  }

  function errorCheck(question, questions) {
    let componantList = []
    questions.forEach(entr => {
      componantList.push(entr.id)
    })
    var output = false
    if (question.conditional) {
      if (question.conditionalList === null) {
        question.conditionalList = []
      }
      question.conditionalList.forEach(condition => {
        condition.subs.forEach(sub => {
          if (components[sub]?.data?.sub !== question?.id || checkSubConection(components[sub], question?.id, componantList) === false) {
            output = true
          }
        })
      })
    }
    return output
  }

  function checkSubConection(componant, sub, componantList) {
    if (typeof componant.data.sub !== "string" || componant.data.sub.length === 0) {
      return false
    } else if (componant.data.sub !== sub) {
      return checkSubConection(components[componant.data.sub], sub, componantList)
    }
    const prevComp = components[componantList[componantList.indexOf(componant.data.id) - 1]]
    if (prevComp === undefined) {
      return true
    }
    if (prevComp.data.id === sub) {
      return true
    } else if (prevComp.data.sub) {
      return checkSubConection(prevComp, sub, componantList)
    }
    return false
  }

  function errorCleanup() {
    candidature.Step.forEach(cand => {
      let componantList = []
      cand.questions.forEach(entr => {
        componantList.push(entr.id)
        if (components[entr.id]) {
          components[entr.id].data.sub = entr.sub
        }
      })
      cand.questions.forEach(question => {
        if (question.conditional && logicError) {
          let conditionsList = []
          question.conditionalList.forEach((condition) => {
            let subList = []
            condition.subs.forEach((sub) => {
              if (components[sub].data.sub === question.id && checkSubConection(components[sub], question.id, componantList) === true) {
                subList.push(sub)
              }
            })
            if (subList.length > 0) {
              condition.subs = subList
              conditionsList.push(condition)
            }
          })
          question.conditionalList = conditionsList
        }
      })
    })
    presave()
    saveCustoms()
    setVisualizer(false)
    setLogic(true)
  }

  useEffect(() => {
    scroll(lineList);
  }, [lineList, scroll])

  function conditionRemove(index) {
    optionM.conditions[index].line.remove();
    var localLineList = lineList;
    localLineList.splice(index, 1);
    setLineList(localLineList);
    optionM.conditions.splice(index, 1);
    save();
  }

  const handleDropToTrashBin = useCallback(
    (dropZone, item) => {
      handleDetails(null, false);
      let position = parseInt(item.path);
      data.questions.splice(position, 1);
      const splitItemPath = item.path.split("-");
      // handle Layout of the drag n drop
      let newLayout = handleRemoveItemFromLayout(layout, splitItemPath);
      newLayout.forEach((line, x) => {
        if (line.children[0].children.length === 0) {
          newLayout.splice(x, 1);
        }
      })

      setComponents(componentsLocal => {
        Object.keys(componentsLocal).forEach(k => {
          if (componentsLocal[k].data.name != null && !data.questions.find(q => q.name === componentsLocal[k].data.name)) {
            delete componentsLocal[k];
          }
        })
        return componentsLocal;
      })
      setLayout(newLayout);
      conditionalListCleanup();
      save();
    },
    [layout, data.questions]
  );

  function saveCustoms() {
    var error = false
    candidature.Step.forEach((cand, i) => {
      cand.questions.forEach(question => {
        if (question.fieldType === "custom input") {
          if (components[question.id])
            question.value = components[question.id]["data"]["value"]
        }
        if (i === curentIndex && errorCheck(question, cand.questions)) {
          error = true
        }
      })
    })
    setLogicError(error)
    //Candidature & components synchro
    conditionalListCleanup();
    save();
  }

  return <div className="h-full relative">
    <Full3Columns
      left={
        (!visualizer && !logic) && <div className="hidden lg:flex lg:flex-col lg:gap-y-2" style={{ maxHeight: "inherit" }}>
          <DataList layout={layout} handleDropToTrashBin={handleDropToTrashBin} candidature={candidature} components={components} payment={payment} />
        </div>
      }
      middleHeader={
        <>
          <div className="flex justify-between mb-2">
            {/* <div className="flex items-center">
              <p className="text-xs text-gray-700 pr-2">{t("Afficher le résultat")} :</p>
              <Switch value={visualizer} loading={loading} onClick={() => { setVisualizer(v => !v) }} />
            </div> */}
            <div className="grid grid-cols-3 w-full">
              <button className={"outline outline-1 mx-2" + (!visualizer && !logic ? " bg-primary" : "")} onClick={(e) => { setVisualizer(false); setLogic(false) }}>{t("Main")}</button>
              <button className={"outline outline-1 mx-2" + (visualizer ? " bg-primary" : "")} onClick={(e) => { setVisualizer(true); setLogic(false) }}>{t("Result")}</button>
              {/* TODO: deactivate logic or popup if a conditionel is broken */}
              <button className={"outline outline-1 mx-2" + (logic ? " bg-primary" : "")} onClick={(e) => {
                if (logicError) {
                  setModalError(true)
                } else {
                  setVisualizer(false);
                  setLogic(true)
                }
              }}>{t("Logic")}</button>
            </div>
            {!visualizer && <div>
              <div className="h-8 w-8">
                <Status loading={upToDate === "loading"} success={upToDate} warning={logicError} />
              </div>
            </div>}
          </div>

          {(!visualizer && !logic) && <DesignerStepper save={save} steps={steps} changeStep={changeStep}
            layout={layout} candidature={candidature} candidatureSetup={candidatureSetup} moveStep={moveStep}
          />}
        </>
      }
      right={(!visualizer && !logic) ?
        <Details components={components} id={selectedItem?.id} handleOnChange={handleOnChangeDetails}
          //  handleOnChangeCondition={conditionRemove} 
          onClose={() => { handleDetails(null, false) }} setRefresh={setRefresh} setSelectedSubs={setSelectedSubs} currStep={curentIndex} />
        : null
      }>
      {visualizer && <Visualizer candidature={candidature} theme={theme} />}
      {(!visualizer && !logic) && <Setup data={data} selectedItem={selectedItem} save={saveCustoms} components={components} setComponents={setComponents} layout={layout} setLayout={setLayout} handleDetails={handleDetails} setRefresh={setRefresh} refresh={refresh} selectedSubs={selectedSubs} payment={payment} />}
      {logic && <Logic candidature={candidature} handleOnChange={handleOnChangeDetails} changeStep={changeStep} />}
    </Full3Columns >
    {/* a remplir */}
    {modalError && <ChoiceModal accept={errorCleanup} cancel={() => setModalError(false)} title={"Error on data logic"} detail={"Do you want to let us resolve this conflict automatically ?"} />}
  </div >
};
export default withTranslation()(Container);